import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <div className="content">
        <div className="content-row even">
          <div className="container">
            <div className="row">&nbsp;</div>
            <div className="row">
              <div className="span12">
                <div className="center">The page that you requested cannot be found.</div>
              </div>
            </div>
            <div className="row">
              <div className="span12">
                <div className="center">
                  <Link to="/">Go back to home page</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
